import { type IndicatorInput } from "@/src/graphql/generated/api/graphql";
import {
    GetIndicatorsForRepoDocument,
    type GetIndicatorsForRepoQuery,
} from "@/src/graphql/generated/gitservice/graphql";
import { GitServiceClientContext } from "@/src/providers/graphql";
import { useContext, useEffect, useState } from "react";
import { type NotNull } from "yup";

export const useGetIndicatorsForRepo = (
    repositoryUrl?: string,
    branchOrTag?: string
) => {
    const gitServiceClient = useContext(GitServiceClientContext);

    if (!gitServiceClient) throw new Error("GitServiceClient is not set");

    const [indicators, setIndicators] = useState<
        GetIndicatorsForRepoQuery["getIndicatorsForRepo"]
    >([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown>();
    const [parameters, setParameters] = useState<IndicatorInput[]>([]);

    useEffect(() => {
        if (!repositoryUrl && branchOrTag === "") {
            return;
        }
        setLoading(true);
        setError(undefined);

        const fetchData = async () => {
            try {
                const indicatorsForRepo =
                    await gitServiceClient.query<GetIndicatorsForRepoQuery>({
                        query: GetIndicatorsForRepoDocument,
                        variables: {
                            repositoryUrl: repositoryUrl,
                            branchOrTag: branchOrTag,
                        },
                    });

                setIndicators((prevState) => {
                    return (
                        indicatorsForRepo.data.getIndicatorsForRepo || []
                    ).map((indicator) => {
                        const existingIndicator = prevState.find(
                            (prevIndicator) =>
                                prevIndicator.name === indicator.name
                        ) as
                            | (NotNull<typeof prevState>[number] & {
                                  parameters: any[]; // type doesn't matter here. just ensure we merge data
                              })
                            | undefined;

                        return {
                            ...indicator,
                            parameters: existingIndicator?.parameters,
                        };
                    });
                });
                return indicatorsForRepo;
            } catch (error) {
                setError(error);
                setIndicators([]);
                setParameters([]);
            } finally {
                setLoading(false);
            }
        };

        void fetchData();
    }, [repositoryUrl, branchOrTag]);

    return { indicators, parameters, loading, error };
};

export default useGetIndicatorsForRepo;
