import * as z from "zod";

export const exchangeProfileSchema = z
    .object({
        id: z.string(),
        name: z.string(),
        url: z.string().url(),
        tagsAndBranches: z.array(
            z.object({
                tagOrBranch: z.string(),
            })
        ),
    })
    .optional();

export type ExchangeProfileType =
    | z.infer<typeof exchangeProfileSchema>
    | undefined;
