import * as z from "zod";

import { format, setMinutes } from "date-fns";

function formatDate(date: Date) {
    return format(setMinutes(date, 0), "yyyy-MM-dd'T'HH:mm");
}

const requiredError = {
    required_error: "validated-question-create.date.errors.required",
};

export const issueAtSchema = z
    .number(requiredError)
    .or(z.string(requiredError))
    .transform((s) => {
        const date = s ? new Date(s) : new Date();
        return formatDate(date);
    });
