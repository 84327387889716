import { Grid, Stack } from "@mui/material";
import Version from "./filters/Version.component";
import ExchangeProfile from "./filters/ExchangeProfile.component";
import Indicators from "./filters/Indicators.component";
// import Indicators from "../../templates/CreateValidatedQuestion/steps/Indicators/Indicators";
import { type useForm, Controller } from "react-hook-form";
import type { ExchangeProfileDto } from "@/src/graphql/generated/api/graphql";
import type { GetIndicatorsForRepoQuery } from "@/src/graphql/generated/gitservice/graphql";
import { type ExchangeProfileType } from "../../templates/CreateValidatedQuestion/schema/ExchangeProfile.schema";

export interface CreateValidatedQuestionFiltersPropTypes {
    control: any;
    errors: ReturnType<typeof useForm>["formState"]["errors"];
    version: string;
    selectedExchangeProfileObject?: ExchangeProfileType;
    exchangeProfiles: ExchangeProfileDto[];
    selectedIndicators: string[];
    indicators: GetIndicatorsForRepoQuery["getIndicatorsForRepo"];
    loading: boolean;
}

export const CreateValidatedQuestionFilters = ({
    control,
    errors,
    selectedIndicators,
    version,
    selectedExchangeProfileObject,
    exchangeProfiles,
    indicators,
    loading,
}: CreateValidatedQuestionFiltersPropTypes) => {
    return (
        <Grid item xs={12} md={4}>
            <Stack gap={2}>
                <Controller
                    control={control}
                    name="exchangeProfile"
                    render={({ field: { onChange, onBlur } }) => {
                        return (
                            <ExchangeProfile
                                onChange={onChange}
                                onBlur={onBlur}
                                errors={errors}
                                exchangeProfiles={exchangeProfiles}
                                step={1}
                            />
                        );
                    }}
                />

                <Controller
                    control={control}
                    name="version"
                    render={({ field: { onChange, onBlur } }) => {
                        return (
                            <Version
                                onChange={onChange}
                                onBlur={onBlur}
                                versions={
                                    selectedExchangeProfileObject?.tagsAndBranches ??
                                    []
                                }
                                errors={errors}
                                step={2}
                            />
                        );
                    }}
                />
                <Controller
                    control={control}
                    name="indicators"
                    disabled={!version && !selectedExchangeProfileObject}
                    render={({ field: { onChange, onBlur } }) => {
                        return (
                            <Indicators
                                onChange={onChange}
                                onBlur={onBlur}
                                indicators={indicators ?? []}
                                selectedIndicators={selectedIndicators}
                                loading={loading}
                                version={version}
                                errors={errors}
                                step={3}
                            />
                        );
                    }}
                />
            </Stack>
        </Grid>
    );
};

export default CreateValidatedQuestionFilters;
