import { Alert, CircularProgress, Container, Grid, Stack } from "@mui/material";
import CreateValidatedQuestionFilters from "../../organisms/CreateValidatedQuestionFilters/CreateValidatedQuestionFilters.component";
import CreateValidatedQuestionForm from "../../organisms/CreateValidatedQuestionForm/CreateValidatedQuestionForm.component";
import { useGetExchangeProfilesQuery } from "@/src/graphql/generated/api/graphql";
import useGetIndicatorsForRepo from "@/src/lib/useGetIndicatorsForRepo/useGetIndicatorsForRepo";
import { useForm, useWatch } from "react-hook-form";
import { exchangeProfileSchema } from "./schema/ExchangeProfile.schema";
import { baseIndicatorsSchema } from "../../organisms/CreateValidatedQuestionForm/fields/Indicators/schemas/baseIndicators.schema";
import { t } from "i18next";

export const CreateValidatedQuestion = () => {
    const {
        data,
        error,
        loading: exchangeProfileLoading,
    } = useGetExchangeProfilesQuery();

    const exchangeProfiles = data?.getExchangeProfiles ?? [];

    const {
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            exchangeProfile: "",
            version: "",
            indicators: [],
        },
    });

    const exchangeProfile = useWatch({
        control,
        name: "exchangeProfile",
    });

    const version = useWatch({
        control,
        name: "version",
    });

    const selectedIndicators: string[] = useWatch({
        control,
        name: "indicators",
    });

    const selectedExchangeProfileObject = exchangeProfileSchema.parse(
        exchangeProfiles?.find((profile) => profile.id === exchangeProfile)
    );
    const { indicators, loading } = useGetIndicatorsForRepo(
        selectedExchangeProfileObject?.url,
        version
    );

    const selectedIndicatorsAsObject = baseIndicatorsSchema.parse(
        indicators?.filter((indicator) =>
            selectedIndicators.includes(indicator.path)
        )
    );

    return (
        <Container>
            {!!error && !exchangeProfileLoading ? (
                <Alert severity="error">
                    {error?.message || t("generic-error")}
                </Alert>
            ) : exchangeProfileLoading ? (
                <Stack py={20} direction="row" justifyContent="center">
                    <CircularProgress />
                </Stack>
            ) : (
                <Grid container spacing={4}>
                    <CreateValidatedQuestionFilters
                        indicators={indicators}
                        selectedIndicators={selectedIndicators}
                        version={version}
                        selectedExchangeProfileObject={
                            selectedExchangeProfileObject
                        }
                        exchangeProfiles={exchangeProfiles}
                        control={control}
                        errors={errors}
                        loading={loading}
                    />
                    <CreateValidatedQuestionForm
                        version={version}
                        exchangeProfile={selectedExchangeProfileObject}
                        selectedIndicators={selectedIndicatorsAsObject}
                    />
                </Grid>
            )}
        </Container>
    );
};

export default CreateValidatedQuestion;
