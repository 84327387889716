import { useTranslation } from "react-i18next";
import DateTimePicker from "@/src/components/atoms/DateTimePicker/DateTimePicker";
import { FormControl, FormHelperText, Stack, Typography } from "@mui/material";
import { type FieldErrors } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export interface IssueAtPropsType {
    onChange: (date: string) => void;
    errors: FieldErrors;
}

export const IssueAt = ({ onChange, errors }: IssueAtPropsType) => {
    const { t } = useTranslation();

    return (
        <Stack direction="column" gap={1}>
            <Stack direction="row" alignItems="center" gap={2}>
                <Typography
                    component="dt"
                    fontWeight="bold"
                    sx={{
                        flex: "0 1 auto",
                    }}
                >
                    {t("validated-question-create.date.title")}:
                </Typography>
                <FormControl
                    fullWidth
                    sx={{
                        flex: "1",
                    }}
                >
                    <DateTimePicker
                        views={["year", "month", "day", "hours"]}
                        defaultValue={new Date()}
                        onChange={(value: Date | null) => {
                            if (value) {
                                onChange(value.toString());
                            }
                        }}
                    />
                </FormControl>
            </Stack>
            <FormHelperText>
                <ErrorMessage errors={errors} name="issueAt" />
            </FormHelperText>
        </Stack>
    );
};

// function isDate(input: unknown): input is Date {
//     return input instanceof Date;
// }

export default IssueAt;
