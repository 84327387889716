import {
    Grid,
    Box,
    Typography,
    Stack,
    FormHelperText,
    Alert,
} from "@mui/material";
import StickyContent from "../../atoms/StickyContent/StickyContent";
import { useTranslation } from "react-i18next";
import FieldPaper from "../FieldPaper/FieldPaper";
import { useForm, Controller } from "react-hook-form";
import SubjectDids from "./fields/SubjectDids/SubjectDids.component";
import IssueAt from "./fields/IssueAt.component";
import InfoBlock from "../../atoms/InfoBlock/InfoBlock";
import Indicators from "./fields/Indicators/Indicators.component";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateValidatedQuestionFormSchema } from "./schema/CreateValidatedQuestionForm.schema";
import {
    GetIssuedVerifiableQueryCredentialsDocument,
    useIssueCredentialsForIndicatorsMutation,
} from "@/src/graphql/generated/api/graphql";
import { useEffect, useState } from "react";
import { type ExchangeProfileType } from "../../templates/CreateValidatedQuestion/schema/ExchangeProfile.schema";
import { type BaseIndicatorType } from "./fields/Indicators/schemas/updatedIndicators.schema";
import CredentialResultsDialog from "../CredentialResultsDialog/CredentialResultsDialog";
import {
    CreateValidatedQuestionResultSchema,
    type CreateValidatedQuestionResultType,
} from "./schema/CreateValidatedQuestionResult.schema";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";

export interface CreateValidatedQuestionFormProps {
    version: string;
    selectedIndicators: BaseIndicatorType[];
    exchangeProfile?: ExchangeProfileType; //TODO: Infer exchange profile from Zod
}

export const CreateValidatedQuestionForm = ({
    version,
    exchangeProfile,
    selectedIndicators,
}: CreateValidatedQuestionFormProps) => {
    const [issueCredentialsForIndicatorsMutation] =
        useIssueCredentialsForIndicatorsMutation();

    const { t } = useTranslation();

    const [openResultsDialog, setOpenResultsDialog] = useState(false);
    const [credentialResults, setCredentialResults] = useState<
        CreateValidatedQuestionResultType | null | undefined
    >(null);
    const [confirmed, setConfirmed] = useState<string[]>([]);

    const form = useForm({
        resolver: zodResolver(CreateValidatedQuestionFormSchema),
        defaultValues: {
            subjectDids: [],
            indicators: selectedIndicators ?? [],
            issueAt: Date.now(),
        },
    });

    const formIndicators = form.watch("indicators");

    // Merge the indicators in the form with the new selected indicators, dropping previously configured indicators
    // when they become unselected. This way, if a user unselects and then re-selects the indicator, he/she will have
    // to reconfigure the parameter consciously.
    useEffect(() => {
        const indicators = formIndicators.filter((formIndicator) => {
            return selectedIndicators.some(
                (selectedIndicator) =>
                    selectedIndicator.name === formIndicator.name
            );
        });
        const newIndicators = selectedIndicators.filter((selectedIndicator) => {
            return !formIndicators.some(
                (formIndicator) => formIndicator.name === selectedIndicator.name
            );
        });
        form.setValue("indicators", [...indicators, ...newIndicators], {
            shouldValidate: true,
        });
    }, [selectedIndicators, form.setValue]);

    // Remove indicators that are no longer selected from the list of confirmed indicators
    useEffect(() => {
        setConfirmed((prev) => {
            return prev.filter((confirmed) => {
                return selectedIndicators.some(
                    (indicator) => indicator.name === confirmed
                );
            });
        });
    }, [selectedIndicators]);

    const minOneIndicatorSelected = selectedIndicators.length > 0;
    const allIndicatorsConfirmed =
        confirmed.length >= selectedIndicators.length;
    const formIsValid =
        form.formState.isValid &&
        minOneIndicatorSelected &&
        allIndicatorsConfirmed;

    const handleSubmit = form.handleSubmit(async (data) => {
        const response = await issueCredentialsForIndicatorsMutation({
            variables: {
                input: {
                    repositoryUrl: exchangeProfile!.url,
                    branchOrTag: version,
                },
                credentialInput: {
                    indicators: data.indicators,
                    issueAt: data.issueAt,
                },
                subjectIds: data.subjectDids,
            },
            refetchQueries: [GetIssuedVerifiableQueryCredentialsDocument],
        }).catch((err) => {
            console.error(err);
            return null;
        });

        if (response) {
            const result = CreateValidatedQuestionResultSchema.parse(
                response?.data?.issueCredentialsForIndicators
            );

            setCredentialResults(result);
            setOpenResultsDialog(true);
        }
    });

    const handleCloseDialog = () => {
        setOpenResultsDialog(false);
    };

    return (
        <Grid item xs={12} md={8}>
            <StickyContent>
                <FieldPaper
                    title={
                        <Typography variant="h6">
                            {t("validated-question-create.general-info")}
                        </Typography>
                    }
                >
                    <Box
                        sx={{
                            p: 0,
                            position: "relative",
                            isolation: "isolate",
                        }}
                    >
                        {form.formState.isSubmitting && (
                            <div
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    display: "grid",
                                    placeItems: "center",
                                    zIndex: "5",
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                        <form
                            style={{
                                opacity: form.formState.isSubmitting ? 0.5 : 1,
                            }}
                            onSubmit={handleSubmit}
                        >
                            <Stack gap={2}>
                                <Controller
                                    name="subjectDids"
                                    control={form.control}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <SubjectDids
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            errors={{
                                                message:
                                                    form.formState.errors
                                                        .subjectDids?.message,
                                            }}
                                            selectedSubjectDids={value}
                                        />
                                    )}
                                />

                                <Controller
                                    name="issueAt"
                                    control={form.control}
                                    render={({ field: { onChange } }) => (
                                        <IssueAt
                                            onChange={onChange}
                                            errors={form.formState.errors}
                                        />
                                    )}
                                />

                                <InfoBlock
                                    label={t(
                                        "validated-question-create.exchangeProfile.title"
                                    )}
                                    value={
                                        exchangeProfile?.name ||
                                        t(
                                            "validated-question-create.exchangeProfile.empty-state"
                                        )
                                    }
                                />

                                <InfoBlock
                                    label={t(
                                        "validated-question-create.version.title"
                                    )}
                                    value={
                                        version ||
                                        t(
                                            "validated-question-create.version.empty-state-form"
                                        )
                                    }
                                />
                                {selectedIndicators.length > 0 ? (
                                    <Indicators
                                        indicators={selectedIndicators}
                                        exchangeProfile={exchangeProfile}
                                        version={version}
                                        form={form}
                                        confirmed={confirmed}
                                        setConfirmed={setConfirmed}
                                    />
                                ) : (
                                    <InfoBlock
                                        label={t(
                                            "validated-question-create.indicators.title"
                                        )}
                                        value={t(
                                            "validated-question-create.indicators.none-selected"
                                        )}
                                    />
                                )}

                                {!selectedIndicators &&
                                form.formState.isSubmitted ? (
                                    <FormHelperText error>
                                        {t(
                                            "validated-question-create.indicators.errors.required"
                                        )}
                                    </FormHelperText>
                                ) : null}

                                {form.formState.isSubmitted ? (
                                    <Alert severity="success">
                                        {t("validated-question-create.success")}
                                    </Alert>
                                ) : (
                                    <LoadingButton
                                        loading={form.formState.isSubmitting}
                                        variant="contained"
                                        type="submit"
                                        disabled={!formIsValid}
                                    >
                                        {t("validated-question-create.submit")}
                                    </LoadingButton>
                                )}
                            </Stack>
                        </form>

                        <CredentialResultsDialog
                            onClose={handleCloseDialog}
                            open={openResultsDialog}
                            results={credentialResults ?? undefined}
                        />
                    </Box>
                </FieldPaper>
            </StickyContent>
        </Grid>
    );
};

export default CreateValidatedQuestionForm;
