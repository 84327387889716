import { useTranslation } from "react-i18next";
import type { FieldErrors, Noop } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Typography, Alert } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import { type ExchangeProfileDto } from "@/src/graphql/generated/api/graphql";

export interface ExchangeProfilePropType {
    step: number;
    exchangeProfiles: ExchangeProfileDto[];
    onChange: Noop;
    onBlur: Noop;
    errors: FieldErrors;
}

export const ExchangeProfile = ({
    step,
    onChange,
    onBlur,
    errors,
    exchangeProfiles,
}: ExchangeProfilePropType) => {
    const { t } = useTranslation();

    return (
        <FieldPaper
            title={
                <Typography variant="h6">
                    {step}.{" "}
                    {t("validated-question-create.exchangeProfile.title")}
                </Typography>
            }
        >
            {exchangeProfiles ? (
                <FormControl fullWidth>
                    <Select
                        data-testid="version"
                        defaultValue={""}
                        size="small"
                        onChange={onChange}
                        onBlur={onBlur}
                    >
                        {exchangeProfiles.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                        <ErrorMessage errors={errors} name="exchangeProfile" />
                    </FormHelperText>
                </FormControl>
            ) : (
                <Alert severity="info">
                    {t("validated-question-create.version.empty-state")}
                </Alert>
            )}
        </FieldPaper>
    );
};

export default ExchangeProfile;
