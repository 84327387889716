import type { Key } from "react";
import { useState } from "react";
import type { RevokeCredentialsMutation } from "src/graphql/generated/api/graphql";
import {
    IssuedCredentialStatusEnum,
    Operator,
    useGetIssuedVerifiableQueryCredentialsQuery,
    useRevokeCredentialsMutation,
    VerifiableCredentialsFieldSelector,
} from "src/graphql/generated/api/graphql";
import Template from "../templates/ValidatedQuestionsBySubjectDidOverview/ValidatedQuestionsBySubjectDidOverview";
import castNumber from "@/src/lib/castNumber/castNumber";

const ValidatedQuestionsBySubjectDidOverview = () => {
    const [revokeResults, setRevokeResults] =
        useState<
            RevokeCredentialsMutation["revokeVerifiableQueryCredentials"]
        >();

    const [openRevokeResultsDialog, setOpenRevokeResultsDialog] =
        useState(false);

    const [mutate] = useRevokeCredentialsMutation();

    const [filter, setFilter] = useState<Key[]>([]);

    const subjectIds = filter.map(castNumber);

    const statusses = Object.values(IssuedCredentialStatusEnum)
        .filter((status) => status !== IssuedCredentialStatusEnum.Revoked)
        .join(",");

    const { data, loading, refetch } =
        useGetIssuedVerifiableQueryCredentialsQuery({
            variables: {
                filters: [
                    ...(subjectIds.length > 0
                        ? [
                              {
                                  selector:
                                      VerifiableCredentialsFieldSelector.SubjectId,
                                  operator: Operator.In,
                                  value: subjectIds.join(","),
                              },
                          ]
                        : []),
                    {
                        selector: VerifiableCredentialsFieldSelector.Status,
                        operator: Operator.In,
                        value: statusses,
                    },
                ],
            },
        });

    const exchangeProfiles =
        data?.getSubjectsWithVerifiableQueryCredentials || [];

    const handleRevoke = (indicators: string[], date: string | null) => {
        mutate({
            variables: {
                ids: indicators,
                revokeAt: date,
            },
        }).then((data) => {
            if (
                data.data?.revokeVerifiableQueryCredentials &&
                data.data?.revokeVerifiableQueryCredentials.length
            ) {
                setOpenRevokeResultsDialog(true);
                setRevokeResults(data.data?.revokeVerifiableQueryCredentials);
                refetch();
            }
        });
    };

    return (
        <Template
            loading={loading}
            subjectDids={exchangeProfiles}
            onRevoke={(indicators, date) => {
                const indicatorIds = indicators.map(
                    (indicator) => indicator.id,
                    []
                );
                handleRevoke(indicatorIds, date);
            }}
            onFilter={setFilter}
            filter={filter}
            revokeResults={revokeResults}
            openRevokeResults={openRevokeResultsDialog}
            onCloseRevokeResults={() => setOpenRevokeResultsDialog(false)}
        />
    );
};

export default ValidatedQuestionsBySubjectDidOverview;
