import { z } from "zod";
import { parameterBaseSchema } from "./parameterBase.schema";
import { ParameterTypeEnum } from "./ParameterType.enum";

/**
 * Schema for quarter and year parameter
 */
export const quarterAndYearSchema = parameterBaseSchema.merge(
    z.object({
        type: z.literal(ParameterTypeEnum.QUARTER_AND_YEAR),
        possibleQuarters: z.array(z.string()).optional(),
        possibleYears: z.array(z.string()).optional(),
        minYearValue: z.coerce.string(),
        maxYearValue: z.coerce.string(),
        quarterPath: z.string(),
        yearPath: z.string(),
    })
);
