import * as z from "zod";
import { baseIndicatorSchema } from "../fields/Indicators/schemas/baseIndicators.schema";

export const CreateValidatedQuestionResultSchema = z
    .array(
        z.object({
            subjectDid: z.string(),
            indicator: baseIndicatorSchema.omit({ parameters: true }),
            successful: z.boolean().optional(),
            unsuccessful: z
                .object({
                    reasonPhrase: z.string(),
                    error: z.string().optional(),
                })
                .nullable()
                .optional(),
        })
    )
    .transform((value) => {
        const successful = value.filter((item) => item.successful === true);
        const unsuccessful = value.filter((item) => item.successful !== true);

        return { successful, unsuccessful };
    });

export type CreateValidatedQuestionResultType = z.infer<
    typeof CreateValidatedQuestionResultSchema
>;
