import { ErrorMessage } from "@hookform/error-message";
import { Alert, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import type { Noop, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { type GetIndicatorsForRepoQuery } from "@/src/graphql/generated/gitservice/graphql";
import FieldPaper from "../../FieldPaper/FieldPaper";

export interface IndicatorPropsType {
    step: number;
    indicators: GetIndicatorsForRepoQuery["getIndicatorsForRepo"];
    selectedIndicators: string[];
    loading: boolean;
    onChange: Noop;
    onBlur: Noop;
    errors: FieldErrors;
    version: string;
}

export const Indicators = ({
    step,
    indicators,
    onChange,
    selectedIndicators,
    errors,
    loading,
    version,
}: IndicatorPropsType) => {
    const { t } = useTranslation();

    const columns = [
        {
            field: "name",
            headerName: t("validated-question-create.indicators.select-all"),
            flex: 1,
        },
    ];

    return (
        <FieldPaper
            title={
                <Typography variant="h6">
                    {step}. {t("validated-question-create.indicators.title")}
                </Typography>
            }
        >
            {errors.length && (
                <Alert severity="error">
                    <ErrorMessage errors={errors} name="indicators" />
                </Alert>
            )}
            {indicators.length > 0 ? (
                <DataGrid
                    loading={loading}
                    density="compact"
                    rows={indicators}
                    columns={columns}
                    pageSizeOptions={[50]}
                    getRowId={(row) => row.path}
                    rowSelectionModel={selectedIndicators}
                    checkboxSelection
                    onRowSelectionModelChange={onChange}
                    sx={{ maxHeight: 400 }}
                />
            ) : indicators.length === 0 && version ? (
                <Alert severity="warning">
                    {t("validated-question-create.indicators.no-results")}
                </Alert>
            ) : (
                <Alert severity="info">
                    {t("validated-question-create.indicators.empty-state")}
                </Alert>
            )}
        </FieldPaper>
    );
};

export default Indicators;
