import { useMemo, useState } from "react";
import type { Key } from "react";
import {
    IssuedCredentialStatusEnum,
    useGetIssuedVerifiableQueryCredentialsQuery,
} from "@/src/graphql/generated/api/graphql";
import {
    Operator,
    VerifiableCredentialsFieldSelector,
} from "@/src/graphql/generated/api/graphql";
import { styled } from "./RevokedIndicatorsCollapse.styles";
import { Collapse, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

type RevokedIndicatorsCollapseProps = {
    subjectDid: string;
    filter: Key[];
};

const RevokedIndicatorsCollapse = ({
    subjectDid,
}: RevokedIndicatorsCollapseProps) => {
    const { t } = useTranslation("common");

    const [isOpen, setIsOpen] = useState(false);
    const { loading, data, error } =
        useGetIssuedVerifiableQueryCredentialsQuery({
            variables: {
                filters: [
                    {
                        selector: VerifiableCredentialsFieldSelector.SubjectId,
                        operator: Operator.In,
                        value: subjectDid.toString(),
                    },
                    {
                        selector: VerifiableCredentialsFieldSelector.Status,
                        operator: Operator.In,
                        value: IssuedCredentialStatusEnum.Revoked,
                    },
                ],
            },
            skip: !isOpen,
            fetchPolicy: "no-cache",
        });

    const isFetched = loading && !data;
    const exchangeProfiles = useMemo(
        () =>
            data?.getSubjectsWithVerifiableQueryCredentials[0]
                ?.exchangeProfiles,
        [isFetched]
    );

    if (error) {
        return <p>{t("revokeditemsError")}</p>;
    }

    return (
        <>
            <Stack
                sx={{
                    mt: 2,
                }}
                alignItems="center"
                component="div"
            >
                <Button
                    sx={{
                        maxWidth: "fit-content",
                    }}
                    onClick={() => setIsOpen((prev) => !prev)}
                >
                    {isOpen ? t("hideRevoked") : t("showRevoked")}
                </Button>
            </Stack>
            <Collapse in={isOpen && !loading && !!data} collapsedSize={0}>
                {!!exchangeProfiles &&
                    exchangeProfiles.map(({ name, version, credentials }) => (
                        <div key={`${subjectDid}-${version}-${name}`}>
                            <h3>
                                {name}
                                <styled.ProfileVersion version={version} />
                            </h3>
                            {credentials.map(({ name, id }, index) => (
                                <Stack
                                    key={id}
                                    sx={{
                                        ml: 3,
                                        p: 2,
                                        backgroundColor:
                                            index % 2 === 0
                                                ? "rgba(186, 42, 94, .05)"
                                                : "",
                                    }}
                                    direction="row"
                                    spacing={2}
                                >
                                    <p key={id}>{name}</p>
                                </Stack>
                            ))}
                        </div>
                    ))}
                {!exchangeProfiles && <p>{t("noRevokedItems")}</p>}
            </Collapse>
        </>
    );
};

export default RevokedIndicatorsCollapse;
