import type { ChangeEvent, Key } from "react";
import { Fragment, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { styled } from "./ValidatedQuestionsBySubjectDidOverview.styles";
import type { RevokeCredentialsMutation } from "@/src/graphql/generated/api/graphql";
import RevokeResultsDialog from "@/src/components/organisms/RevokeResultsDialog/RevokeResultsDialog";
import ConfirmRevoke from "@/src/components/organisms/ConfirmRevoke/ConfirmRevoke";

import SubjectDidFilter from "@/src/components/organisms/SelectSubjectDid/SelectSubjectDid";
import { format, setMinutes } from "date-fns";
import RevokedIndicatorsCollapse from "@/src/components/organisms/RevokedIndicatorsCollapse/RevokedIndicatorsCollapse";
import type {
    IndicatorForOverview,
    Credential,
    ExchangeProfile,
    SubjectDidsForOverview,
} from "./validatedQuestions.types";

export type ValidatedQuestionsOverviewProps = {
    loading?: boolean;
    subjectDids: SubjectDidsForOverview[];
    onRevoke: (indicators: IndicatorForOverview[], date: string) => void;
    onFilter: (filter: Key[]) => void;
    filter: Key[];
    revokeResults?: RevokeCredentialsMutation["revokeVerifiableQueryCredentials"];
    onCloseRevokeResults: () => void;
    openRevokeResults?: boolean;
};

function ValidatedQuestionsOverview({
    loading = false,
    subjectDids = [],
    onRevoke = () => {},
    onFilter = () => {},
    filter = [],
    revokeResults = [],
    openRevokeResults = false,
    onCloseRevokeResults,
}: ValidatedQuestionsOverviewProps) {
    const { t } = useTranslation("common", {
        keyPrefix: "validated-questions",
    });
    const [selected, setSelected] = useState<string[]>([]);

    const [toRevoke, setToRevoke] = useState<{ id: string; name: string }[]>(
        []
    );

    const [revokeDate, setRevokeDate] = useState<string>(
        formatDate(new Date())
    );

    //
    // SubjectDid Checkboxes
    // -------------------------------------------------------------------------
    const isSubjectDidChecked = (subjectDid: SubjectDidsForOverview): boolean =>
        subjectDid.exchangeProfiles?.every((profile) =>
            profile.credentials.every((credential) =>
                selected.includes(credential.id)
            )
        );

    const isSubjectDidIndeterminate = (
        subjectDid: SubjectDidsForOverview
    ): boolean =>
        subjectDid.exchangeProfiles?.some((profile) =>
            profile.credentials.some((credential) =>
                selected.includes(credential.id)
            )
        );

    function handleSubjectDidOnChange(subjectDid: SubjectDidsForOverview) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked && subjectDid.exchangeProfiles) {
                setSelected(
                    [
                        ...selected,
                        ...subjectDid.exchangeProfiles.flatMap((ep) =>
                            ep.credentials.map((credential) => credential.id)
                        ),
                    ].flat()
                );
            } else {
                setSelected((prevSelection) =>
                    prevSelection.filter((id) =>
                        subjectDid.exchangeProfiles.every((profile) =>
                            profile.credentials.every(
                                (credential) => credential.id !== id
                            )
                        )
                    )
                );
            }
        };
    }

    function isExchangeProfileChecked(exchangeProfile: ExchangeProfile) {
        return exchangeProfile.credentials.every((credential) =>
            selected.includes(credential.id)
        );
    }

    function isExchangeProfileIndeterminate(exchangeProfile: ExchangeProfile) {
        return exchangeProfile.credentials.some((credential) =>
            selected.includes(credential.id)
        );
    }

    function handleExchangeProfileOnChange(exchangeProfile: ExchangeProfile) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                setSelected((prevSelection) => [
                    ...prevSelection,
                    ...exchangeProfile.credentials.map((i) => i.id),
                ]);
            } else {
                setSelected((prevSelection) =>
                    prevSelection.filter(
                        (id) =>
                            !exchangeProfile.credentials.find(
                                (i) => i.id === id
                            )
                    )
                );
            }
        };
    }

    function handleSetRevokeDate(date: Date | null) {
        if (date === null) return;
        const formatted = formatDate(date);

        setRevokeDate(formatted);
    }

    //
    // Indicator Checkboxes
    // -------------------------------------------------------------------------

    const selectIndicator = (indicator: IndicatorForOverview) => {
        setSelected((prevState) => [...prevState, indicator.id]);
    };

    const deselectIndicator = (indicator: IndicatorForOverview) => {
        setSelected((prevState) =>
            prevState.filter((id) => id !== indicator.id)
        );
    };

    const toggleIndicator =
        (indicator: IndicatorForOverview) =>
        ({ target: { checked } }: ChangeEvent<HTMLInputElement>): any =>
            checked ? selectIndicator(indicator) : deselectIndicator(indicator);

    //
    // Bulk Menu Actions
    // -------------------------------------------------------------------------

    const revokeSingleItem = ({
        name,
        id,
    }: IndicatorForOverview | Credential) => {
        setToRevoke([{ name, id }]);
    };

    const revokeAllSelected = () => {
        const selectedItems = subjectDids
            .flatMap((subjectDid) =>
                subjectDid.exchangeProfiles.flatMap((profile) =>
                    profile.credentials.map((credential) => ({
                        id: credential.id,
                        name: credential.name,
                    }))
                )
            )
            .filter((item) => selected.includes(item.id));
        setToRevoke(selectedItems);
    };

    const cancelSelection = () => {
        setSelected([]);
    };

    //
    // SubjectDidFilter
    // -------------------------------------------------------------------------

    function handleFilterChange(event: { target: { value: Key[] } }) {
        onFilter(event.target.value);
    }

    return (
        <>
            <styled.Container>
                <styled.Header
                    title={t("title")}
                    button={{
                        to: "new",
                        label: t("new-validated-question"),
                    }}
                />
                <styled.Main>
                    <SubjectDidFilter
                        value={filter}
                        onChange={handleFilterChange}
                    />

                    {loading && <styled.Loading />}

                    {subjectDids.map((subjectDid, index, array) => (
                        <Box key={`${subjectDid.id}-${index}`}>
                            <styled.ExchangeProfileControl
                                label={subjectDid.name}
                                variant="h2"
                                checkbox={{
                                    checked: isSubjectDidChecked(subjectDid),
                                    indeterminate:
                                        isSubjectDidIndeterminate(subjectDid),
                                    onChange:
                                        handleSubjectDidOnChange(subjectDid),
                                }}
                            />

                            {subjectDid.exchangeProfiles.map((profile) => (
                                <Fragment
                                    key={`${profile.name}-${profile.version}-${subjectDid.id}`}
                                >
                                    <styled.ProfileRow>
                                        <styled.ExchangeProfileControl
                                            label={profile.name}
                                            checkbox={{
                                                checked:
                                                    isExchangeProfileChecked(
                                                        profile
                                                    ),
                                                indeterminate:
                                                    isExchangeProfileIndeterminate(
                                                        profile
                                                    ),
                                                onChange:
                                                    handleExchangeProfileOnChange(
                                                        profile
                                                    ),
                                            }}
                                            pl={3}
                                            pt={1}
                                            variant="h4"
                                        />
                                        <styled.ProfileVersion
                                            version={profile.version}
                                        />
                                    </styled.ProfileRow>
                                    <styled.IndicatorRowGroup>
                                        {profile.credentials.map(
                                            (
                                                credential: Credential,
                                                index: number
                                            ) => (
                                                <styled.IndicatorControl
                                                    key={`${profile.name}-${profile.version}-${credential.id}`}
                                                    checked={selected.includes(
                                                        credential.id
                                                    )}
                                                    even={index % 2 === 0}
                                                    onChange={toggleIndicator(
                                                        credential
                                                    )}
                                                    label={credential.name}
                                                    button={{
                                                        onClick:
                                                            revokeSingleItem.bind(
                                                                null,
                                                                credential
                                                            ),
                                                        label: "",
                                                    }}
                                                    status={credential.status}
                                                    issuedAt={
                                                        credential.issuedAt
                                                    }
                                                />
                                            )
                                        )}
                                    </styled.IndicatorRowGroup>
                                </Fragment>
                            ))}
                            <RevokedIndicatorsCollapse
                                filter={filter}
                                subjectDid={subjectDid.id}
                            />
                            <styled.EchangeProfileDivider
                                visible={index < array.length - 1}
                            />
                        </Box>
                    ))}
                </styled.Main>
                <styled.Footer
                    visible={Boolean(selected.length)}
                    revokeButton={{
                        label: t("revoke-selected"),
                        onClick: revokeAllSelected,
                    }}
                    cancelSelectionButton={{
                        label: t("cancel-selection"),
                        onClick: cancelSelection,
                    }}
                />
            </styled.Container>

            <RevokeResultsDialog
                open={openRevokeResults}
                results={revokeResults}
                onClose={onCloseRevokeResults}
            />
            <ConfirmRevoke
                open={toRevoke.length > 0}
                indicators={toRevoke}
                handleDateChange={handleSetRevokeDate}
                onClose={({ confirmed }) => {
                    if (confirmed) onRevoke(toRevoke, revokeDate);
                    setToRevoke([]);
                }}
            />
        </>
    );
}

export default memo(ValidatedQuestionsOverview);

export function formatDate(date: Date) {
    return format(setMinutes(date, 0), "yyyy-MM-dd'T'HH:mm");
}
