import { z } from "zod";
import { parameterBaseSchema } from "./parameterBase.schema";
import { ParameterTypeEnum } from "./ParameterType.enum";

const dateRangeDateInput = z
    .any()
    .nullable()
    .transform((s) => (Array.isArray(s) ? s.at(0) : s));

export const dateRangeInputSchema = parameterBaseSchema
    .merge(
        z.object({
            type: z.literal(ParameterTypeEnum.DATE_RANGE),
            startDate: dateRangeDateInput.optional(),
            startDatePath: z.string().nullish(),
            endDate: dateRangeDateInput.optional(),
            endDatePath: z.string().nullish(),
            maxDatePath: z.string().nullish(),
            minDatePath: z.string().nullish(),
        })
    )
    .transform(
        ({ startDatePath, endDatePath, minDatePath, maxDatePath, ...s }) => ({
            ...s,
            startDatePath: startDatePath ?? minDatePath ?? null,
            endDatePath: endDatePath ?? maxDatePath ?? null,
        })
    );
