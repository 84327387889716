import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import { Stack, Typography } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import { useGetSubjectDidsQuery } from "src/graphql/generated/api/graphql";
import getNameById from "@/src/components/organisms/CreateValidatedQuestionForm/fields/SubjectDids/getNameById";
import { type Noop } from "react-hook-form";

export interface SubjectDidsPropType {
    onChange: (...event: unknown[]) => void;
    onBlur: Noop;
    errors?: {
        message?: string;
    };
    selectedSubjectDids: string[];
}

const SubjectDids = ({
    onChange,
    onBlur,
    errors,
    selectedSubjectDids,
}: SubjectDidsPropType) => {
    const { t } = useTranslation();

    const { data: subjects } = useGetSubjectDidsQuery();

    const subjectDids = subjects?.getSubjectDids || [];

    const getName = getNameById(subjectDids);

    const isChecked = (id: string) => selectedSubjectDids.includes(id);

    const list = useMemo(
        () =>
            subjectDids.map((subjectDid) => ({
                id: subjectDid.id,
                name: subjectDid.name,
                isChecked: isChecked(subjectDid.id),
            })),
        [subjectDids, selectedSubjectDids]
    );

    return (
        <Stack direction="column" gap={1}>
            <Stack direction="row" alignItems="center" gap={2}>
                <Typography
                    component="dt"
                    fontWeight="bold"
                    sx={{
                        flex: "0 1 auto",
                    }}
                >
                    {t("validated-question-create.subject.title")}:
                </Typography>
                <FormControl
                    fullWidth
                    sx={{
                        flex: "1",
                    }}
                >
                    <Select
                        data-testid="subject"
                        defaultValue={[]}
                        size="small"
                        multiple={true}
                        onChange={onChange}
                        onBlur={onBlur}
                        renderValue={(values) => values.map(getName).join(", ")}
                    >
                        {list.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                                <Checkbox checked={item.isChecked} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>

            {errors?.message && (
                <FormHelperText error>
                    {t(errors?.message || "")}
                </FormHelperText>
            )}
        </Stack>
    );
};

export default SubjectDids;
