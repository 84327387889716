import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useCreateSubjectDID = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "create-subject-did",
    });

    const schema = yup
        .object({
            subjectDid: yup.object().shape({
                name: yup.string().required().label(t("name")),
                zinlDid: yup.string().required().label(t("zinlDid")),
                iri: yup.string().label(t("iri")),
            }),
        })
        .required();

    const form = useForm<yup.InferType<typeof schema>>({
        resolver: yupResolver(schema),
        reValidateMode: "onChange",
        mode: "onChange",
    });

    return {
        form,
    };
};
