import * as z from "zod";
import { parametersSchema } from "../fields/Parameters/schemas";
import { issueAtSchema } from "./IssueAt.schema";

export const CreateValidatedQuestionFormSchema = z.object({
    issueAt: issueAtSchema,
    subjectDids: z.array(z.string().or(z.number())).min(1, {
        message: "validated-question-create.subject.errors.required",
    }),
    indicators: z
        .array(
            z.object({
                name: z.string(),
                path: z.string(),
                parameters: parametersSchema.optional(),
            })
        )
        .min(1, {
            message: "validated-question-create.indicators.errors.required",
        }),
});
