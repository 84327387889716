import { useTranslation } from "react-i18next";
import type { FieldErrors, Noop } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Typography, Alert } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FieldPaper from "src/components/organisms/FieldPaper/FieldPaper";
import { type TagOrBranch } from "@/src/graphql/generated/api/graphql";

export interface VersionPropTypes {
    step: number;
    onChange: Noop;
    onBlur: Noop;
    errors: FieldErrors;
    versions?: TagOrBranch[];
}

export const Version = ({
    step,
    onChange,
    onBlur,
    errors,
    versions,
}: VersionPropTypes) => {
    const { t } = useTranslation();

    return (
        <FieldPaper
            title={
                <Typography variant="h6">
                    {step}. {t("validated-question-create.version.title")}
                </Typography>
            }
        >
            {versions && versions.length > 0 ? (
                <FormControl fullWidth>
                    <Select
                        data-testid="version"
                        defaultValue={""}
                        size="small"
                        onChange={onChange}
                        onBlur={onBlur}
                    >
                        {versions.map((item: TagOrBranch) => (
                            <MenuItem
                                value={item.tagOrBranch}
                                key={item.tagOrBranch}
                            >
                                {item.tagOrBranch}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                        <ErrorMessage errors={errors} name="version" />
                    </FormHelperText>
                </FormControl>
            ) : (
                <Alert severity="info">
                    {t("validated-question-create.version.empty-state")}
                </Alert>
            )}
        </FieldPaper>
    );
};

export default Version;
