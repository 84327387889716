import { z } from "zod";
import { ParameterTypeEnum } from "./ParameterType.enum";

/**
 * Base Schema for reference date parameter
 *
 * Common properties for all parameters.
 */
export const parameterBaseSchema = z.object({
    type: z
        .enum([
            ParameterTypeEnum.DATE_RANGE,
            ParameterTypeEnum.QUARTER_AND_YEAR,
            ParameterTypeEnum.REFERENCE_DATE,
        ])
        .or(z.string())
        .optional(),
    name: z.string(),
    path: z.string(),
});
