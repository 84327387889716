import { type ComponentProps, type MouseEvent } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "./HealthcareProviderExcerptOverview.styles";
import PaginatedList from "src/components/organisms/PaginatedList/PaginatedList";
import ConfirmRevoke from "src/components/organisms/ConfirmRevoke/ConfirmRevoke";
import RevokeResultsDialog from "src/components/organisms/RevokeResultsDialog/RevokeResultsDialog";
import {
    useGetIssuedCredentialsLazyQuery,
    useRevokeHealthcareProviderExcerptCredentialMutation,
} from "@/src/graphql/generated/api/graphql";

type HealthcareProviderDataSummary = {
    id: string;
    name: string;
    cocNumber: string;
};

const HealthcareProviderExcerptOverview = () => {
    const [query, setQuery] = useState("");

    const [toRevoke, setToRevoke] = useState<HealthcareProviderDataSummary[]>(
        []
    );

    const [confirmedRevoke, setConfirmedRevoke] = useState<
        HealthcareProviderDataSummary[]
    >([]);

    const [revokeResults, setRevokeResults] =
        useState<ComponentProps<typeof RevokeResultsDialog>["results"]>();

    const { t } = useTranslation("common", {
        keyPrefix: "healthcare-provider-excerpts-overview",
    });

    const [page, setPage] = useState(0);

    const [pageSize, setPageSize] = useState(10);

    const [getIssuedCredentials, { loading, data }] =
        useGetIssuedCredentialsLazyQuery({
            variables: {
                pagination: {
                    page,
                    pageSize,
                },
                search: query,
            },
        });

    // Total count
    const count = data?.getIssuedHealthcareProviderExcerptCredentials.rowCount;

    // flatmap to get the actual healthcare provider data
    const healthcareProviderDataSummaries =
        data?.getIssuedHealthcareProviderExcerptCredentials.rows.map(
            ({ id, healthcareProvider }) => ({
                ...healthcareProvider,
                id,
                name: `${healthcareProvider.name} - ${healthcareProvider.cocNumber}`,
            })
        );

    const onFilter = (query: string) => {
        setQuery(query);
    };

    const handlePageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPageSize(Number(event.target.value));
    };

    const handleDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
        const id = event.currentTarget.dataset.itemId;
        const item =
            healthcareProviderDataSummaries?.filter((item) => item.id === id) ??
            [];

        setToRevoke(item);
    };

    const handleRevokeConfirmClose = ({
        confirmed,
    }: {
        confirmed?: boolean | null;
    }) => {
        if (confirmed) setConfirmedRevoke(toRevoke);
        setToRevoke([]);
    };

    const handleRevokeResultsDialogOnClose = () => {
        setRevokeResults(undefined);
    };

    const [revoke] = useRevokeHealthcareProviderExcerptCredentialMutation({
        variables: {
            ids: confirmedRevoke.map(({ id }) => id),
        },
        onCompleted: () => {
            setConfirmedRevoke([]);
        },
        onError: (error) => {
            console.error(error);
            setConfirmedRevoke([]);
        },
    });

    useEffect(() => {
        if (confirmedRevoke.length) {
            revoke().finally(() => {
                setRevokeResults(undefined);
            });
        }
    }, [confirmedRevoke]);

    useEffect(() => {
        getIssuedCredentials(); // TODO: add debounce
    }, [query, page, pageSize]);

    return (
        <>
            <styled.Container>
                <styled.Header
                    title={t("title")}
                    button={{
                        to: "new",
                        label: t("healthcare-provider-excerpts"),
                    }}
                />
                <styled.Main>
                    <styled.FilterControl
                        label={t("filter")}
                        onChange={(event) => onFilter(event.target.value)}
                    />
                    <PaginatedList<any>
                        data={healthcareProviderDataSummaries}
                        loading={loading}
                        query={query}
                        count={count}
                        page={page}
                        rowsPerPage={pageSize}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        onDeleteClick={handleDeleteClick}
                    />
                </styled.Main>
            </styled.Container>
            <ConfirmRevoke
                open={Boolean(toRevoke.length)}
                indicators={toRevoke}
                onClose={handleRevokeConfirmClose}
            />
            <RevokeResultsDialog
                open={Boolean(revokeResults)}
                results={revokeResults}
                onClose={handleRevokeResultsDialogOnClose}
                navigateToPath="/healthcare-provider-excerpts"
            />
        </>
    );
};

export default HealthcareProviderExcerptOverview;
