import * as z from "zod";

export const baseIndicatorSchema = z.object({
    name: z.string(),
    path: z.string(),
    parameters: z
        .array(
            z.object({
                type: z.string(),
            })
        )
        .optional(),
});

export const baseIndicatorsSchema = baseIndicatorSchema.array();
